import React from "react";
import { SEO, MarkdownContent, Image, DynamicForm } from "@bluefin/components";
import { Grid, Segment } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";

export default class AllThingsExtensionsPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      fishermanWebsiteComponent,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          title={fishermanBusinessWebsitePage.seoTitle}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <Grid
          className={"custom-page-hair-extension-course custom-page-grid"}
          style={{}}
          stackable={true}
        >
          <Grid.Row className={""} style={{ padding: 16 }} columns={1}>
            <Grid.Column
              className={""}
              style={{ padding: 16 }}
              textAlign={"center"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "543109d9-3a68-4cc8-8139-81c6b725a7d3",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className={""} style={{ padding: 16 }} columns={2}>
            <Grid.Column className={""} style={{ padding: 16 }}>
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{}}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "721e2b2a-3461-4039-87e0-0c7dd40ff720",
                    numToSelect: 1,
                  })}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={""}
              style={{ padding: 16 }}
              textAlign={"left"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "501df20b-a640-409a-bcdb-becb042b7853",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className={""} style={{ padding: 16 }} columns={2}>
            <Grid.Column
              className={""}
              style={{ padding: 16 }}
              textAlign={"left"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "5a89a3ce-02fa-445d-b7ce-6ef54bc95b01",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column className={""} style={{ padding: 16 }}>
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{}}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "fa5004a6-7c42-4242-81fa-9d73a95172b4",
                    numToSelect: 1,
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={""}
            style={{ padding: 16 }}
            textAlign={"center"}
            verticalAlign={"top"}
            columns={2}
          >
            <Grid.Column className={""} style={{ padding: 16 }}>
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{}}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "da7cd674-200f-47b0-9dbc-190d41c01432",
                    numToSelect: 1,
                  })}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={""}
              style={{ padding: 16 }}
              textAlign={"left"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "5b9f154c-2e93-4a3a-83ed-663bb4fd2c54",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={""}
            style={{ padding: 16 }}
            textAlign={"center"}
            verticalAlign={"top"}
          >
            <Grid.Column
              className={""}
              style={{ padding: 16 }}
              width={8}
              textAlign={"left"}
              verticalAlign={"top"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <DynamicForm
                  className={""}
                  style={{}}
                  businessId={1623}
                  businessInfoId={6799}
                  websiteId={7887}
                  locationId={1703}
                  componentConfigurationId={49126}
                  header={fishermanWebsiteComponent.data.header}
                  fields={fishermanWebsiteComponent.data.dynamicFormFields}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(_id: { eq: 24435 }) {
      title
      seoTitle
      description
      components {
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        order
        fastId
        componentIdentifier
        contentNodes {
          content
        }
      }
    }
    fishermanWebsiteComponent(
      fastId: { eq: "CustomForm" }
      componentIdentifier: { eq: "38eb3af0-a894-485b-9f8e-2a13a42ae01d" }
    ) {
      data {
        header
        dynamicFormFields {
          name
          label
          options {
            label
            value
          }
          required
          inputType
          widgetType
          placeholder
        }
      }
    }
  }
`;
